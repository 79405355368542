<template>
	<div>
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Sao kê thanh toán</h5>
				<span class="btn-close" @click="closeShow()"></span>
			</div>
			<div class="modal-body">
				<div align="center" class="container-fluid">
					<img :src="getImageURL(url_statements)" style="width: auto; height: auto;">
					<div class="mt-2 text-black-50">
						<small>Hình ảnh được chính bệnh nhân cung cấp</small>
					</div>							
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  export default {
    name: 'BankStatements',
    props: ['url_statements'],
    data () {
      return {
      }
    },
    methods: {
      getImageURL (path) {
        return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
        // return require(path)
      },
      closeShow () {
        let self = this
        self.$emit('showCreate', false)
      }
    }
  }
</script>
<style scoped>
	#txt-clinic-name:focus, #txt-address:focus {
		outline: none;
	}
	.txt-label {
		font-weight: bold !important; 
		font-size: 15px;
	}
	.bound-big {
		width: 100%; 
		height: 100%; 
		overflow-y: hidden;
		align-items: center;
		display: flex;
	}
	.btn-close {
		cursor: pointer;
	}
	.txt-red{
		color: red;
	}
	.bound-chil {
		border: none;
		border-radius: 20px;
	}
	.bound-bound-title{
		background-color: #20419B; 
		border-top-right-radius: 20px; 
		border-top-left-radius: 20px;
	}
	.bound-title{
		color: #fff; 
		font-weight: bold; 
		font-size: 20px;
	}
</style>