<template>
  <div>
    <div class="mx-3 mb-3 d-flex justify-content-between py-3 border-bottom">
      <h3 class="font-weight-bold">{{ $t('lbl_list_of_bank_accounts') }}</h3>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="goToList"
        class="cursor-pointer"
      >
        <path
          d="M6 18.4998L17.9998 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 18.4998L6 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div
      class="row border-0 container bg-white d-flex justify-content-center mt-1"
      style="min-height: 75vh; margin: 0 5px;"
    >
      <div class="col-sm-5 mt-3">
        <div
          class="list-bank"
          style="max-height: 462px; overflow-y: auto; overflow-x: hidden;"
          v-if="list_bank_total > 0"
          id="list-bank"
        >
          <div
            v-for="(item, index) in my_bank_account.data"
            :key="index"
            style="margin-right: 20px;"
          >
            <div
              class="col-12 item bound-item"
              @click="selectBankAccount(item, index)"
              :class="[index === default_bank ? 'action' : '', index === 0 ? '' : 'mt-3']"
            >
              <DisplayBankLogo
                v-show="(item ? true : false) && is_show_logo"
                :short_name="item ? splitStrBankName(item.bank_name) : splitStrBankName('no-found')"
              ></DisplayBankLogo>
              <div class="font-weight-bold mt-2">{{ $t('lbl_bank') }}: {{item.bank_name}}</div>
              <div>{{ $t('lbl_bank_account_number') }}: {{item.bank_number}}</div>
              <div>{{ $t('lbl_account_holder') }}: {{item.bank_account.toUpperCase()}}</div>
              <div>{{ $t('lbl_bank_branch') }}: {{item.bank_branch}}</div>
            </div>
          </div>
        </div>
        <div id="list-bank">
          <div v-if="is_loading">
            <div class="col-12 mt-3 algin-center" v-if="list_bank_total <= 0">
              <div align="center" v-if="isMyClinic()">
                <div
                  class="alert alert-primary"
                  role="alert"
                >{{ $t('lbl_no_bank_account_has_been_add') }}</div>
              </div>
              <div v-if="!isMyClinic()">
                <div
                  class="alert alert-warning"
                  role="alert"
                >{{ $t('lbl_you_don_not_have_permission_to_access') }}</div>
              </div>
            </div>
            <button
              v-if="isMyClinic() && (list_bank_total === 0 || list_bank_total)"
              class="btn bg-pri bd-pri text-white btn-create algin-center mt-4"
              @click="displayModelCreate()"
            >{{ $t('lbl_add_a_bank_account') }}</button>
          </div>
        </div>
      </div>
      <div class="col-sm-1 col-0" v-if="list_bank_total > 0"></div>
      <div class="col-sm-6 mt-3" v-if="list_bank_total > 0">
        <h3 class="font-weight-bold">{{ $t('lbl_bank_account_information') }}</h3>
        <form>
          <div id="form-info">
            <div class="form-group mt-3">
              <label
                class="font-weight-bold"
                for="txt-bank-number"
              >{{ $t('lbl_bank_account_number') }}</label>
              <div id="copy-bank-number">
                <input
                  type="text"
                  class="form-control mt-2 txt-blue04"
                  id="txt-bank-number"
                  :readonly="is_show"
                  :value="[bank_update ? bank_update.bank_number : '']"
                  placeholder
                  autocomplete="off"
                  @input="params.bank_number = $event.target.value"
                />
              </div>
              <div class="btn-copy" @click="copyText('copy-bank-number')"></div>
            </div>
            <div class="form-group mt-3">
              <label class="font-weight-bold" for="txt-bank-name">{{ $t('lbl_bank') }}</label>
              <v-select
                :disabled="is_show"
                class="form-control mt-2 txt-blue04"
                id="txt-bank-name"
                :options="getBankFilter()"
                label="item_label"
                v-model="selected_bank"
                :placeholder="$t('lbl_enter_keyword')"
                :filterable="false"
                @search="searchBank"
              >
                <div slot="no-options">Not found!</div>
                <template v-slot:option="item">
                  <span
                    class="font-weight-bold"
                  >({{ item.shortName }} - {{ item.bankCode }}) {{getLanguage() ? item.vn_name : item.en_name }}</span>
                </template>
              </v-select>
            </div>
            <div class="form-group mt-3">
              <label class="font-weight-bold" for="txt-bank-account">{{ $t('lbl_account_holder') }}</label>
              <input
                type="text"
                class="form-control mt-2 txt-blue04"
                id="txt-bank-account"
                :readonly="is_show"
                :value="[bank_update ? bank_update.bank_account : '']"
                placeholder
                autocomplete="off"
                @input="params.bank_account = $event.target.value"
              />
            </div>
            <div class="form-group mt-3">
              <label class="font-weight-bold" for="txt-bank-branch">{{ $t('lbl_bank_branch') }}</label>
              <input
                type="text"
                class="form-control mt-2 txt-blue04"
                id="txt-bank-branch"
                :readonly="is_show"
                :value="[bank_update ? bank_update.bank_branch : '']"
                placeholder
                autocomplete="off"
                @input="params.bank_branch = $event.target.value"
              />
            </div>
          </div>
          <div align="right" class="mt-4">
            <button
              type="button"
              class="btn bg-pri bd-pri text-white btn-sm w-150-h-40 mr-2 mt-1"
              @click.prevent="copyText('form-info')"
              v-if="is_show && list_bank_total > 0"
            >{{ $t('lbl_copy_information') }}</button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm w-150-h-40 mr-2 mt-1"
              @click.prevent="enableEdit()"
              v-if="isMyClinic() && is_show"
            >{{ $t('lbl_edit') }}</button>
            <button
              type="submit"
              class="btn bg-pri bd-pri text-white btn-sm w-150-h-40 mr-2 mt-1"
              @click.prevent="deleteBankAccounts()"
              v-if="!is_show"
            >{{ $t('lbl_btn_delete') }}</button>
            <button
              type="submit"
              class="btn bg-pri bd-pri text-white btn-sm w-150-h-40 mr-2 mt-1"
              @click.prevent="editBankAccounts()"
              v-if="!is_show"
            >{{ $t('lbl_update') }}</button>
            <button
              type="button"
              class="btn w-150-h-40 btn-outline-primary mr-2 mt-1"
              @click.prevent="cancel()"
              v-if="!is_show"
            >{{ $t('lbl_cancel') }}</button>
          </div>
        </form>
      </div>
      <div class="modal" tabindex="-1" role="dialog" id="modal-create-bank" v-if="isMyClinic()">
        <div class="modal-dialog" role="document">
          <CreateBankAccount @closeShowCreate="closeShowCreate"></CreateBankAccount>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import CreateBankAccount from './CreateBankAccount.vue'
import DisplayBankLogo from './DisplayBankLogo.vue'
import vSelect from 'vue-select'
import appUtils from '../utils/appUtils'
export default {
  name: 'BankInformation',
  components: { CreateBankAccount, DisplayBankLogo, vSelect },
  data () {
    return {
      is_loading: false,
      my_clinic: Object,
      list_clinic_account: {},
      my_bank_account: [],
      clinic_singer: Object,
      is_create: false,
      is_show_create: false,
      list_bank_total: 0,
      list_clinic_total: 0,
      params: {
        clinic_id: 0,
        bank_name: '',
        bank_account: '',
        bank_number: '',
        bank_branch: ''
      },
      is_show: true,
      is_show_logo: true,
      short_name: '',
      list_bank: [],
      is_lang_vn: true,
      create: 1,
      key_search: '',
      selected_bank: {},
      is_change: false,
      default_bank: 0,
      bank_update: {
        id: '',
        bank_name: '',
        bank_account: '',
        bank_number: '',
        bank_branch: ''
      },
      is_reload: true,
      request_class: ''
    }
  },
  computed: {},
  mounted () {
    let self = this
    if (!self.can('update-bank-info') && appUtils.isDoctor()) {
      alert(this.$t('lbl_you_don_not_have_permission_to_access'))
      self.router.push('HomePage')
      return
    }
    self.getRequestClass()
    self.getBank()
    self.getClinicId()
    self.getBankAccounts()
    var bank = document.querySelector('#list-bank')
    bank.addEventListener('scroll', e => {
      if (Math.ceil(bank.scrollTop + bank.clientHeight) >= bank.scrollHeight) {
        if (self.my_bank_account.count < self.my_bank_account.total) {
          self.getAction(self.actions.count + 10)
        }
      }
    })
    Promise.all([self.getBank(), self.getBankAccounts()]).then(value => {
      if (
        self.my_bank_account &&
        self.my_bank_account.data &&
        self.my_bank_account.count > 0
      ) {
        const selected_bank = self.list_bank.filter(item =>
          item.shortName
            .toLowerCase()
            .includes(
              self.splitStrBankName(
                self.my_bank_account.data[0].bank_name.toLowerCase()
              )
            )
        )
        self.selected_bank = selected_bank[0]
      }
    })
  },
  methods: {
    getRequestClass () {
      let self = this
      if (appUtils.isAdmin()) {
        self.request_class = 'AdminRequest'
      } else if (appUtils.isDoctor()) {
        self.request_class = 'DoctorRequest'
      }
    },
    getBankFilter () {
      let self = this
      if (self.key_search) {
        return self.list_bank.filter(
          item =>
            item.en_name.toLowerCase().indexOf(self.key_search.toLowerCase()) >=
              0 ||
            item.vn_name.toLowerCase().indexOf(self.key_search.toLowerCase()) >=
              0 ||
            item.bankCode
              .toLowerCase()
              .indexOf(self.key_search.toLowerCase()) >= 0 ||
            item.shortName
              .toLowerCase()
              .indexOf(self.key_search.toLowerCase()) >= 0
        )
      } else {
        return self.list_bank
      }
    },
    getLanguage () {
      var lang = localStorage.getItem('language')
      if (lang === 'vi') {
        return true
      } else {
        return false
      }
    },
    async getBank () {
      try {
        let self = this
        self.is_loading = false
        var result = await self.$rf.getRequest('AuthRequest').getBank()
        self.list_bank = result.data
        self.list_bank.map(x => {
          x.item_label = this.getLanguage()
            ? x.vn_name + ' (' + x.shortName + ')'
            : x.en_name + ' (' + x.shortName + ')'
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.is_loading = true
      }
    },
    selectedBank (bank_name) {
      let self = this
      try {
        const selected_bank = self.list_bank.filter(item =>
          item.shortName
            .toLowerCase()
            .includes(self.splitStrBankName(bank_name.toLowerCase()))
        )
        self.selected_bank = selected_bank[0]
      } catch (e) {
        console.log(e)
      }
    },
    searchBank (search) {
      let self = this
      self.key_search = search
    },
    closeShowCreate (is_show, is_success) {
      let self = this
      window.$('#modal-create-bank').modal('hide')
      if (is_success) {
        self.is_reload = false
        self.getBankAccounts()
      }
    },
    async getClinicId () {
      try {
        let self = this
        self.my_clinic = JSON.parse(localStorage.getItem('global_clinic'))
        self.params.clinic_id = localStorage.getItem('global_wp_id')
      } catch (e) {
        console.log(e)
      }
    },
    isMyClinic () {
      let self = this
      try {
        if (appUtils.isDoctor()) {
          if (self.my_clinic.doctor_id) {
            if (
              self.my_clinic.doctor_id === self.my_clinic.pivot.doctor_id ||
              this.can('update-bank-info')
            ) {
              return true
            } else {
              return false
            }
          }
        } else if (appUtils.isAdmin()) {
          return true
        }
      } catch (e) {
        console.log(e)
      }
    },
    enableEdit () {
      let self = this
      document.getElementById('txt-bank-number').focus()
      self.is_show = false
      self.is_change = true
    },
    displayModelCreate () {
      window.$('#modal-create-bank').modal('show')
    },
    selectBankAccount (item, index) {
      let self = this
      self.create = 2
      self.selectedBank(item.bank_name)
      self.default_bank = index
      self.bank_update.id = item.id
      self.bank_update.bank_account = item.bank_account
      self.bank_update.bank_number = item.bank_number
      self.bank_update.bank_branch = item.bank_branch
    },
    async getBankAccounts () {
      try {
        let self = this
        self.is_loading = false
        var params = {
          clinic_id: localStorage.getItem('global_wp_id'),
          sort: 'created_at',
          sort_by: 'desc'
        }
        const resultv2 = await self.$rf
          .getRequest(self.request_class)
          .getBankAccountsById(params)
        self.list_bank_total = resultv2.data.total
        self.my_bank_account = resultv2.data
        if (self.list_bank_total === 1) {
          self.is_reload = true
        }
        if (self.list_bank_total > 0 && self.is_reload) {
          self.bank_update.id = self.my_bank_account.data[0].id
          self.bank_update.bank_account =
            self.my_bank_account.data[0].bank_account
          self.bank_update.bank_number =
            self.my_bank_account.data[0].bank_number
          self.bank_update.bank_branch =
            self.my_bank_account.data[0].bank_branch
          if (self.list_bank_total === 1) {
            const selected_bank = self.list_bank.filter(item =>
              item.shortName
                .toLowerCase()
                .includes(
                  self.splitStrBankName(
                    self.my_bank_account.data[0].bank_name.toLowerCase()
                  )
                )
            )
            self.selected_bank = selected_bank[0]
          }
        }
        self.is_reload = true
      } catch (e) {
        console.log(e)
      } finally {
        this.is_loading = true
      }
    },
    async editBankAccounts () {
      try {
        let self = this
        self.is_loading = false
        var params = {
          clinic_id: self.params.clinic_id,
          bank_name: self.selected_bank
            ? self.is_lang_vn
              ? self.selected_bank.vn_name +
                ' (' +
                self.selected_bank.shortName +
                ')'
              : self.selected_bank.en_name +
                ' (' +
                self.selected_bank.shortName +
                ')'
            : self.bank_update.bank_name,
          bank_account: self.params.bank_account
            ? self.params.bank_account
            : self.bank_update.bank_account,
          bank_number: self.params.bank_number
            ? self.params.bank_number
            : self.bank_update.bank_number,
          bank_branch: self.params.bank_branch
            ? self.params.bank_branch
            : self.bank_update.bank_branch
        }
        var id = self.bank_update.id
        await self.$rf
          .getRequest(self.request_class)
          .postBankAccountsUpdate(id, params)
        self.$toast.clear()
        self.create = 1
        self.$toast.open({
          message: this.$t('lbl_update_acc_bank_successful'),
          type: 'success'
        })
        self.bank_update.bank_account = params.bank_account
        self.bank_update.bank_number = params.bank_number
        self.bank_update.bank_branch = params.bank_branch
        self.create = 1
        self.is_reload = false
        self.getBankAccounts()
        self.is_show = true
        self.is_change = false
      } catch (e) {
        console.log(e)
      } finally {
        this.is_loading = true
      }
    },
    async deleteBankAccounts () {
      try {
        let self = this
        var r = confirm(this.$t('lbl_are_you_sure'))
        if (r) {
          self.is_loading = false
          await self.$rf
            .getRequest(self.request_class)
            .postBankAccountsDelete(self.bank_update.id)
          self.$toast.clear()
          self.$toast.open({
            message: this.$t('lbl_delete_acc_bank_successful'),
            type: 'success'
          })
          self.getBankAccounts()
          self.cancel()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.is_loading = true
      }
    },
    copyText (id_element) {
      var code = document.getElementById(id_element)
      const range = document.createRange()
      var selection = window.getSelection()
      selection.removeAllRanges()
      range.selectNodeContents(code)
      selection.addRange(range)
      try {
        if (this.isSupport('copy')) {
          document.execCommand('copy')
          selection.removeAllRanges()
          this.$toast.open({
            message: this.$t('lbl_copy_successfully'),
            type: 'success'
          })
        } else {
          this.$toast.open({
            message: this.$t('lbl_action_copy_is_limited_from_your_browser'),
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    isSupport (cmd) {
      return document.queryCommandSupported(cmd)
    },
    splitStrBankName (name) {
      var shortname = ''
      if (name) {
        if (name.includes('(')) {
          shortname = name
            .split('(')[1]
            .split(')')[0]
            .toLowerCase()
        }
      }
      return shortname
    },
    cancel () {
      let self = this
      self.is_show = true
      self.is_change = false
    },
    goToList () {
      this.$router.push({ path: '/ws-mgmt' })
    }
  }
}
</script>
<style scoped>
.v-select #vs1__combobox {
  border: none !important;
}
.bound-big {
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /*background-color: red;*/
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 3;
  overflow-y: hidden;
}
.w-150-h-40 {
  width: 150px !important;
  height: 40px !important;
}
.btn-create {
  padding: 10px 30px;
  width: 250px;
}
.btn-create:before {
  content: "\f055";
  font-family: "FontAwesome";
  margin-right: 5px;
}
.action {
  background-color: #eef3ff;
  color: #20419b;
}
.item:hover {
  cursor: pointer;
}
.algin-center {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn-copy {
  width: 20px;
  height: 20px;
  background-image: url("../../public/assets/images/icon/icon_copy_code.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*background-color: red;*/
  margin-top: -30px;
  margin-left: 94%;
  cursor: pointer;
}
.display-none {
  display: none;
}
.txt-red {
  color: red;
}
.bound-item {
  border: 1px solid #eef3ff;
  border-radius: 10px;
  padding: 10px 10px;
}
.w-150-h-70 {
  width: 150px;
  height: 70px;
}
.txt-blue04 {
  color: #20419b;
  font-weight: 500;
  font-size: 16px;
}
</style>